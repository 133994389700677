interface IData {
  name: String;
  email: String;
  phone?: String;
  formName: String;
}

export const createLead = async (body: IData) => {
  try {
    await useFetch('/api/createLead', {
      method: 'POST',
      body,
    });
  } catch (error) {}
};
